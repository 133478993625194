import { Alert } from "react-bootstrap";

function Alerts(props) {
    let variant = ""
    let header = ""

    if (props.ok === true) {
        variant = "success"
        header = "Success"
    }
    else {
        variant = "danger"
        header = "Failed"
    }
    if (props.header) {
        header = props.header
    }
    return (
        <Alert show={props.show} variant={variant}>
            <Alert.Heading>{header}</Alert.Heading>
            <p>{props.message}</p>
        </Alert>
    )
}
export default Alerts

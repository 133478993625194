// App.js
import React, { useEffect, useState } from 'react';
import { Database } from "./components/Constants";
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from './containers/LoginForm';
import ThemeSelector from "./styling/ThemeSelector";
import './styling/App.css';

function App() {
    const [account, setAccount] = useState('')
    const [user, setUser] = useState('')
    const [loggedIn, setLoggedIn] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        tokenLogin()
    }, [])

    const tokenLogin = () => {
        setLoading(true)
        if (localStorage.getItem('token')) {//if a token is stored in their browser, try to access current user while page loads
            fetch(Database + '/api/current_user/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then((response) => {
                if (!response.ok) throw new Error(response.status);//if token is expired throw error
                else return response.json();
            })
                .then((json) => {//if token is valid setstate with currentuser info
                    setAccount(json.account)
                    setUser(json.user)
                    setLoggedIn(true)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log('token expired or invalid: ' + error);
                    setLoggedIn(false)
                    setLoading(false)
                });
        }
        else {
            setLoading(false)
        }
    }

    const passwordLogin = (data) => {
        fetch(Database + '/token-auth/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);//if password is incorrect throw error
            else return response.json();
        }).then(json => {
            localStorage.setItem('token', json.access);
            setAccount(json.account)
            setUser(json.user)
            setLoggedIn(true)
        }).catch((error) => {
            window.alert("Incorrect Password or Username");
        });
    }

    const handleLogout = () => {//Return state to what it was originally 
        localStorage.removeItem('token');
        setAccount('')
        setUser('')
        setLoggedIn(false)
        setLoading(false)
    }

    const handleLogin = (data) => {
        passwordLogin(data)
    }

    return (<>
        {(!loading) &&
            <>
                {loggedIn && user && (account || user.is_staff)
                    ? <ThemeSelector account={account} user={user} handleLogout={handleLogout} />
                    : <LoginForm handleLogin={handleLogin} />
                }
            </>
        }
    </>)

}

export default App

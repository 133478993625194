import React, { useState } from 'react';
import { Col, Row, Carousel } from "react-bootstrap";
import { Database, StaticUrl } from "../components/Constants";
import Alert from "../components/Alert"
import { PatternFormat } from 'react-number-format';

function SignupForm(props) {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [confVerificationCode, setConfVerificationCode] = useState('')
    const [page, setPage] = useState(0)

    const [show, setShow] = useState(false)
    const [ok, setOk] = useState(false)
    const [apiResponse, setApiResponse] = useState('')

    const handleErrors = async (response) => {
        const bodyText = await response.text().then(body => { return body })
        if (!response.ok) {
            throw (bodyText)
        }
        return (bodyText)
    }

    const verifyInfo = () => {
        const code = Math.random()
        setVerificationCode(code)
        let jsonBody = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "password": password,
            "conf_password": confirmPassword,
            "verification_code": code,
        };
        fetch((Database + '/api/login_page_new_user_email/'), {
            method: "POST",
            body: (JSON.stringify(
                jsonBody
            )),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(handleErrors)
            .then(response => {
                setShow(true)
                setOk(true)
                setApiResponse(response)
                setPage(1)
            })
            .catch(error => {
                setShow(true)
                setOk(false)
                setApiResponse(error)
            })
    }

    const createUser = () => {
        let jsonBody = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "password": password,
            "conf_password": confirmPassword,
            "verification_code": verificationCode,
            "conf_verification_code": confVerificationCode,
        };
        fetch((Database + '/api/login_page_new_user/'), {
            method: "POST",
            body: (JSON.stringify(
                jsonBody
            )),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(handleErrors)
            .then(response => {
                setShow(true)
                setOk(true)
                setApiResponse(response)
                props.handleLogin(
                    {
                        "username": email,
                        "password": password
                    }
                )
            })
            .catch(error => {
                setShow(true)
                setOk(false)
                setApiResponse(error)
            })
    }

    const page0 = () => {//Enter name, email, & password page
        return <>
            <div style={{ paddingTop: "0px" }}>
                <h2><b>Welcome!
                </b></h2>
            </div>
            <form onSubmit={e => {
                e.preventDefault();
                verifyInfo()
            }}>
                <label htmlFor="firstName"><b>First Name</b></label>
                <br />
                <input className='loginInput' type="text" value={firstName || ""} name="firstName" onChange={event => setFirstName(event.target.value)} required />
                <br />
                <label htmlFor="lastName"><b>Last Name</b></label>
                <br />
                <input className='loginInput' type="text" value={lastName || ""} name="lastName" onChange={event => setLastName(event.target.value)} required />
                <br />
                <label htmlFor="email"><b>Email</b></label>
                <br />
                <input className='loginInput' type="text" value={email || ""} name="email" onChange={event => setEmail(event.target.value)} required />
                <br />
                <label htmlFor="password"><b>Password</b></label>
                <br />
                <input className='loginInput' type="password" value={password || ""} name="password" onChange={event => setPassword(event.target.value)} required minLength={6} />
                <br />
                <label htmlFor="confirmPassword"><b>Confirm Password</b></label>
                <br />
                <input className='loginInput' type="password" value={confirmPassword || ""} name="confirmPassword" onChange={event => setConfirmPassword(event.target.value)} minLength={6} required />
                <div style={{ paddingTop: "1rem" }}>
                    <input type="submit" value="Next" />
                </div>
                <div style={{ paddingTop: "1rem" }}>
                    <input type="submit" value="Back" onClick={() => props.goToPage()} />
                </div>
                <Alert show={show} ok={ok} message={apiResponse} />
            </form>
        </>
    }

    const page1 = () => {//Verify email page
        return <>
            <form onSubmit={e => {
                e.preventDefault();
                createUser()
            }}>
                <div style={{ paddingTop: "0px" }}>
                    <h2><b>Check your email for a verification code.
                    </b></h2>
                </div>
                <PatternFormat format="######" mask="_" placeholder='######' value={confVerificationCode || ""} name="confVerificationCode" onChange={event => setConfVerificationCode(event.target.value)} className='loginInput' required />
                <div style={{ paddingTop: "1rem" }}>
                    <input type="submit" value="Next" />
                </div>
                <Alert show={show} ok={ok} message={apiResponse} />
            </form>
        </>
    }

    return (<>
        <Row className="py-5">
            <Col style={{ textAlign: "right" }}>
                <img src={StaticUrl + "/stock_photos/Sign in Stock.jpg"}
                    alt=''
                    style={{ minWidth: '13rem', maxWidth: '35rem', width: 'inherit', boxShadow: "-1em 1em #002b54" }}
                />
            </Col>
            <Col className="p-3" style={{ textAlign: "left" }}>
                <Carousel controls={false} indicators={false} interval={null} activeIndex={page}>
                    <Carousel.Item>
                        {page0()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {(page === 1) && page1()}
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    </>
    )
}
export default SignupForm
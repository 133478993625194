import Routing from "../containers/Routing";
import { Col, Row, Container, Navbar } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import Nav from '../containers/Nav';
import { StaticUrl } from "../components/Constants";
import React, { Suspense } from "react";

const AtPropertiesTheme = React.lazy(() => import('../styling/AtPropertiesTheme'));
const UHMTheme = React.lazy(() => import('../styling/UHMTheme'));
const TwoMenTheme = React.lazy(() => import('../styling/TwoMenTheme'));
const DDKTheme = React.lazy(() => import('../styling/DDKTheme'));
const GrandWelcomeTheme = React.lazy(() => import('../styling/GrandWelcomeTheme'));
const JewelryTheme = React.lazy(() => import('../styling/JewelryTheme'));

function ThemeSelector(props) {
    const chooseLogo = () => {
        if (props.account.account_type === "Two Men")
            return <img src={StaticUrl + "/SVGs/TMAAT Logo.svg"} alt="" width="300" height="50" />
        else if (props.account.account_type === "At Properties")
            return <img src={StaticUrl + "/SVGs/At Properties Logo.svg"} alt="" height="40" />
        else if (props.account.account_type === "UHM")
            return <img src={StaticUrl + "/SVGs/UHM Logo.svg"} alt="" height="70" />
        else if (props.account.account_type === "Grand Welcome")
            return <img src={StaticUrl + "/SVGs/Grand Welcome Logo.svg"} alt="" height="70" />
        else if ((props.account.account_type === "Jewelry") || (props.account.account_type === "MVI"))
            return <><img src={StaticUrl + "/SVGs/OnSitelogo.png"} alt="" height="65" /></>
        else
            return <img src={StaticUrl + "/SVGs/DDK Logo.svg"} width="192" height="90" alt="DDK logo" />
    }

    const chooseText = () => {
        if ((props.account.account_type === "Jewelry") || (props.account.account_type === "MVI"))
            return <h4 style={{ display: "inline-block", verticalAlign: "middle" }}><b>OnSite for Jewelry Marketing</b></h4>
        else
            return <></>
    }

    //     <Col >
    //     <img src={StaticUrl + props.path} height="250" width="250" alt="header" />
    // </Col>
    // <Col style={{ whiteSpace: "nowrap", display: "inherit" }}>
    //     <h1 style={{ textAlign: "right", paddingRight: "5px" }}>{props.header}</h1>
    //     <h5 style={{ display: "inline-block", verticalAlign: "text-top" }}>{props.tooltip}</h5>
    // </Col>

    const chooseLogo2ElectricBoogaloo = () => {
        if ((props.account.account_type === "Jewelry") || (props.account.account_type === "MVI"))
            return <></>
        // return <Col style={{ textAlign: "right" }}>
        //     <><img src={StaticUrl + "/SVGs/DDK Logo.svg"} height="65" alt="DDK logo" /></>
        // </Col>

        else
            return <></>
    }


    const chooseStyle = () => {
        return (
            <>
                <React.Suspense fallback={<></>}>
                    {(props.account.account_type === "Two Men") && <TwoMenTheme />}
                    {(props.account.account_type === "At Properties") && <AtPropertiesTheme />}
                    {(props.account.account_type === "UHM") && <UHMTheme />}
                    {(props.account.account_type === "Demo") && <DDKTheme />}
                    {(props.account.account_type === "DDK") && <DDKTheme />}
                    {(props.account.account_type === "Grand Welcome") && <GrandWelcomeTheme />}
                    {((props.account.account_type === "Jewelry") || (props.account.account_type === "MVI")) && <JewelryTheme />}
                </React.Suspense>
            </>
        )
    }
    //current user and account objects are passed in by props
    return (
        <><Suspense fallback={<div></div>}>
            {chooseStyle()}
            {(props.user.is_staff)
                ? <div style={{ position: "relative", minHeight: "100vh", paddingBottom: "96px" }}>
                    <Navbar bg="light">
                        <Container style={{ maxWidth: "100%", display: "inline-block" }}>
                            <Row className="px-3 align-items-center">
                                <Col>
                                    <NavLink to="" >
                                        {chooseLogo()}
                                    </NavLink>
                                </Col>
                                <Col>
                                    <div style={{ float: "right" }}>
                                        <h3 >
                                            Hello, {props.user.username}
                                        </h3>
                                        <Nav
                                            logged_in={true}
                                            handleLogout={props.handleLogout}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Navbar>
                    <main>
                        <Routing user={props.user} account={props.account} handleLogout={props.handleLogout} />
                    </main>
                </div>
                //Client
                : <main style={{ position: "relative", minHeight: "100vh" }}>
                    <Navbar bg="opaqueNavbar">
                        <Container style={{ maxWidth: "100%", display: "inline-block" }}>
                            <Row className="px-0 align-items-center">
                                <Col>
                                    <NavLink to="" >
                                        {chooseLogo()}
                                    </NavLink>
                                    {chooseText()}
                                </Col>
                                {chooseLogo2ElectricBoogaloo()}
                            </Row>
                        </Container>
                    </Navbar>
                    <Routing user={props.user} account={props.account} handleLogout={props.handleLogout} />
                </main>
            }
        </Suspense>
        </>
    )
}
export default ThemeSelector

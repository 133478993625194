import React, { useState } from 'react';
import { Container, Col, Row, Carousel } from "react-bootstrap";
import { Database, StaticUrl } from "../components/Constants";
import SignupForm from "./SignupForm";

const AtPropertiesTheme = React.lazy(() => import('../styling/AtPropertiesTheme'));
const UHMTheme = React.lazy(() => import('../styling/UHMTheme'));
const TwoMenTheme = React.lazy(() => import('../styling/TwoMenTheme'));
const GrandWelcomeTheme = React.lazy(() => import('../styling/GrandWelcomeTheme'));
const JewelryTheme = React.lazy(() => import('../styling/JewelryTheme'));

function LoginForm(props) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [page, setPage] = useState(0)
    const [background, setBackground] = useState('')

    const fetchAccountType = () => {
        fetch(Database + '/api/logintype/?username=' + username)
            .then(res => res.json())
            .then(json => {
                if (json.accountType === "staff") {
                    goToPage(1, "")
                }
                else if (json.accountType === "standard") {
                    goToPage(1, "")
                }
                else if (json.accountType === "atproperties") {
                    goToPage(2, "atproperties")
                }
                else if (json.accountType === "uhm") {
                    goToPage(3, "uhm")
                }
                else if (json.accountType === "twomen") {
                    goToPage(4, "twomen")
                }
                else if (json.accountType === "grandwelcome") {
                    goToPage(5, "grandwelcome")
                }
                else if ((json.accountType === "jewelry")||(json.accountType === "mvi")) {
                    goToPage(6, "jewelry")
                }
                else if (json.accountType === null) {
                    alert("username not found")
                }
            })
    }

    const goToPage = (location, background) => {
        setPage(location)
        setBackground(background)
    }

    const page0 = () => {//Username page
        return <Container fluid >
            <Row className="py-5">
                <div style={{ "height": "90px" }}></div>
                {/* <img src={StaticUrl + "/SVGs/DDK Logo.svg"} height="90" alt="DDK logo" /> */}
            </Row>
            <Row className="py-5 align-items-center">
                <Col style={{ textAlign: "right" }}>
                    <img src={StaticUrl + "/stock_photos/Mail Stock Photo.jpg"}
                        alt=''
                        style={{ minWidth: '13rem', maxWidth: '35rem', width: 'inherit', boxShadow: "-1em 1em #002b54" }}
                    />
                </Col>
                <Col className="p-3" style={{ textAlign: "left" }}>
                    <div style={{ marginTop: "0px" }}>
                        <h2><b>Sign in to start connecting with your best prospective customers.
                        </b></h2>
                    </div>
                    <form onSubmit={e => {
                        e.preventDefault()
                        fetchAccountType()
                    }}>
                        <label htmlFor="username"><b>Email</b></label>
                        <br />
                        <input
                            className='loginInput'
                            type="text"
                            name="username"
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                        />
                        <div style={{ marginTop: "1rem" }}>
                            <input type="submit" value="Next" />
                            <br />
                        </div>
                        <br />
                    </form>
                    <label htmlFor="username" style={{ marginTop: "1rem" }}><b>New User?</b></label>
                    <br />
                    <div style={{ marginTop: ".5rem" }}>
                        <input type="submit" value="Create Account" onClick={() => goToPage(7)} />
                    </div>
                </Col>
            </Row>
        </Container>
    }


    const page1 = () => {//Standard sign in page(staff & unbranded users)
        return <Container fluid>
            <Row className="py-5">
                <img src={StaticUrl + "/SVGs/DDK Logo.svg"} height="90" alt="DDK logo" />
            </Row>
            <form onSubmit={e => {
                e.preventDefault()
                props.handleLogin({
                    "username": username,
                    "password": password
                })
            }}>
                <Row className="py-5 align-items-center">
                    <Col style={{ textAlign: "right" }}>
                        <img src={StaticUrl + "/stock_photos/Mail Stock Photo.jpg"}
                            alt=''
                            style={{ minWidth: '13rem', maxWidth: '35rem', width: 'inherit', boxShadow: "-1em 1em #002b54" }}
                        />
                    </Col>
                    <Col className="p-3" style={{ textAlign: "left" }}>
                        <div style={{ paddingTop: "0px" }}>
                            <h2><b>Greetings!
                            </b></h2>
                        </div>
                        <label ><b>{username}</b></label>
                        <br />
                        <label htmlFor="password">Password</label>
                        <br />
                        <input
                            className='loginInput'
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="on"
                        />
                        <div style={{ paddingTop: "1rem" }}>
                            <input type="submit" value="Sign in" />
                        </div>
                    </Col>
                </Row>
            </form>
        </Container>
    }

    const page2 = () => {//atproperties sign in page
        return <Container fluid>
            <Row className="py-5">
                <img src={StaticUrl + "/SVGs/At Properties Logo.svg"} alt="" height="40" />
            </Row>
            <form onSubmit={e => {
                e.preventDefault()
                props.handleLogin({
                    "username": username,
                    "password": password
                })
            }}>
                <Row className="py-5 align-items-center">
                    <Col style={{ textAlign: "right" }}>
                        <img src={StaticUrl + "/stock_photos/BusinessStockPhotoSmall.jpg"}
                            alt=''
                            style={{ minWidth: '13rem', maxWidth: '35rem', width: 'inherit', boxShadow: "-1em 1em black" }}
                        />
                    </Col>
                    <Col className="p-3" style={{ textAlign: "left" }}>
                        <div style={{ paddingTop: "0px" }}>
                            <h2><b>Communicate
                                with your customers
                                better than ever.
                            </b></h2>
                        </div>
                        <label ><b>{username}</b></label>
                        <br />
                        <label htmlFor="password">Password</label>
                        <br />
                        <input
                            className='loginInput'
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="on"
                        />
                        <div style={{ paddingTop: "1rem" }}>
                            <input type="submit" value="Sign in" />
                        </div>
                    </Col>
                </Row>
            </form>
        </Container>
    }
    const page3 = () => {//uhm sign in page
        return <Container fluid>
            <Row className="py-5">
                <img src={StaticUrl + "/SVGs/UHM Logo.svg"} alt="" height="60" />
            </Row>
            <form onSubmit={e => {
                e.preventDefault()
                props.handleLogin({
                    "username": username,
                    "password": password
                })
            }}>
                <Row className="py-5 align-items-center">
                    <Col style={{ textAlign: "right" }}>
                        <img src={StaticUrl + "/stock_photos/BusinessStockPhotoSmall.jpg"}
                            alt=''
                            style={{ minWidth: '13rem', maxWidth: '35rem', width: 'inherit', boxShadow: "-1em 1em #3EB049" }}
                        />
                    </Col>
                    <Col className="p-3" style={{ textAlign: "left" }}>
                        <div style={{ paddingTop: "0px" }}>
                            <h2><b>Find and communicate
                                with your customers
                                better than ever.
                            </b></h2>
                        </div>
                        <label ><b>{username}</b></label>
                        <br />
                        <label htmlFor="password">Password</label>
                        <br />
                        <input
                            className='loginInput'
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="on"
                        />
                        <div style={{ paddingTop: "1rem" }}>
                            <input type="submit" value="Sign in" />
                        </div>
                    </Col>
                </Row>
            </form>
        </Container>
    }

    const page4 = () => {//two men sign in page
        return <Container fluid>
            <Row className="py-5">
                <img src={StaticUrl + "/SVGs/TMAAT Logo.svg"} alt="" width="90" height="100" />
            </Row>
            <form onSubmit={e => {
                e.preventDefault()
                props.handleLogin({
                    "username": username,
                    "password": password
                })
            }}>
                <Row className="py-5 align-items-center">
                    <Col style={{ textAlign: "right" }}>
                        <img src={StaticUrl + "/stock_photos/MovingStockPhotoWithDog.jpg"}
                            alt=''
                            style={{ minWidth: '13rem', maxWidth: '35rem', width: 'inherit', boxShadow: "-1em 1em #F7A800" }}
                        />
                    </Col>
                    <Col className="p-3" style={{ textAlign: "left" }}>
                        <div style={{ paddingTop: "0px" }}>
                            <header>
                                <h2><b>Communicate
                                    with your customers
                                    better than ever.
                                </b></h2>
                            </header>
                        </div>
                        <label ><b>{username}</b></label>
                        <br />
                        <label htmlFor="password">Password</label>
                        <br />
                        <input
                            className='loginInput'
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="on"
                        />
                        <div style={{ paddingTop: "1rem" }}>
                            <input type="submit" value="Sign in" />
                        </div>
                    </Col>
                </Row>
            </form>
        </Container>
    }

    const page5 = () => {//Grand Welcome sign in page
        return <Container fluid>
            <Row className="py-5">
                <img src={StaticUrl + "/SVGs/Grand Welcome Logo.svg"} alt="" width="90" height="100" />
            </Row>
            <form onSubmit={e => {
                e.preventDefault()
                props.handleLogin({
                    "username": username,
                    "password": password
                })
            }}>
                <Row className="py-5 align-items-center">
                    <Col style={{ textAlign: "right" }}>
                        <img src={StaticUrl + "/stock_photos/RentalHome.jpg"}
                            alt=''
                            style={{ minWidth: '13rem', maxWidth: '35rem', width: 'inherit', boxShadow: "-1em 1em #F7A800" }}
                        />
                    </Col>
                    <Col className="p-3" style={{ textAlign: "left" }}>
                        <div style={{ paddingTop: "0px" }}>
                            <header>
                                <h2><b>Communicate
                                    with your prospects
                                    better than ever.
                                </b></h2>
                            </header>
                        </div>
                        <label ><b>{username}</b></label>
                        <br />
                        <label htmlFor="password">Password</label>
                        <br />
                        <input
                            className='loginInput'
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="on"
                        />
                        <div style={{ paddingTop: "1rem" }}>
                            <input type="submit" value="Sign in" />
                        </div>
                    </Col>
                </Row>
            </form>
        </Container>
    }

    const page6 = () => {//Jewelry sign in page
        return <Container fluid>
            <Row className="py-5">
                <div style={{ "height": "90px" }}></div>
                {/* <img src={StaticUrl + "/SVGs/DDK Logo.svg"} height="90" alt="DDK logo" /> */}
            </Row>
            <form onSubmit={e => {
                e.preventDefault()
                props.handleLogin({
                    "username": username,
                    "password": password
                })
            }}>
                <Row className="py-5 align-items-center">
                    <Col style={{ textAlign: "right" }}>
                        <img src={StaticUrl + "/stock_photos/JewelryCounter.jpg"}
                            alt=''
                            style={{ minWidth: '13rem', maxWidth: '35rem', width: 'inherit', boxShadow: "-1em 1em #002b54" }}
                        />
                    </Col>
                    <Col className="p-3" style={{ textAlign: "left" }}>
                        <div style={{ paddingTop: "0px" }}>
                            <header>
                                <h2><b>Communicate
                                    with your prospects
                                    better than ever.
                                </b></h2>
                            </header>
                        </div>
                        <label ><b>{username}</b></label>
                        <br />
                        <label htmlFor="password">Password</label>
                        <br />
                        <input
                            className='loginInput'
                            type="password"
                            name="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="on"
                        />
                        <div style={{ paddingTop: "1rem" }}>
                            <input type="submit" value="Sign in" />
                        </div>
                    </Col>
                </Row>
            </form>
        </Container>
    }

    const page7 = () => {//Username page
        return <Container fluid >
            <Row className="py-5">
                <img src={StaticUrl + "/SVGs/DDK Logo.svg"} height="90" alt="DDK logo" />
            </Row>
            <SignupForm handleLogin={props.handleLogin} goToPage={() => goToPage(0, "")} />
        </Container>
    }

    const ThemeSelector = () => {
        return (
            <>
                <React.Suspense fallback={<></>}>
                    {(page === 2) && <AtPropertiesTheme />}
                    {(page === 3) && <UHMTheme />}
                    {(page === 4) && <TwoMenTheme />}
                    {(page === 5) && <GrandWelcomeTheme />}
                    {(page === 6) && <JewelryTheme />}
                </React.Suspense>
            </>
        )
    }

    return (
        <div className={`background ${background}`} style={{ position: "relative", minHeight: "100vh" }}>
            <main >
                <ThemeSelector />
                <Carousel controls={false} indicators={false} interval={null} activeIndex={page}>
                    <Carousel.Item>
                        {page0()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {(page === 1) && page1()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {(page === 2) && page2()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {(page === 3) && page3()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {(page === 4) && page4()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {(page === 5) && page5()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {(page === 6) && page6()}
                    </Carousel.Item>
                    <Carousel.Item>
                        {(page === 7) && page7()}
                    </Carousel.Item>
                </Carousel>
            </main>
        </div >
    )
}
export default LoginForm
import { Link } from 'react-router-dom';

function Nav(props) {
  const logged_out_nav = (
    <ul>
      <Link to="" onClick={() => props.displayForm('login')} className="a-nav">login</Link>
    </ul>
  )

  const logged_in_nav = (
    <ul>
      <Link to="" onClick={props.handleLogout} className="a-nav" >logout</Link>
    </ul>
  )
  return <>{props.logged_in ? logged_in_nav : logged_out_nav}</>;
}
export default Nav

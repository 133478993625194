import React, { lazy } from "react";

//https://create-react-app.dev/docs/code-splitting/
//https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
//Client specific routers and any of their unique dependencies will load separately from eachother 

const MortgageRouting = lazy(() => import('./MortgageRouting'));
const TwoMenRouting = lazy(() => import('./TwoMenRouting'));
const GrandWelcomeRouting = lazy(() => import('./GrandWelcomeRouting'));
const JewelryRouting = lazy(() => import('./JewelryRouting'));
const DDKRouting = lazy(() => import('./DDKRouting'));
const StaffRouting = lazy(() => import('./StaffRouting'));

function Routing(props) {
    const pickClientRouter = () => {//Used to show different account types different routers
        if (props.account.account_type === "At Properties" || props.account.account_type === "UHM"|| props.account.account_type === "Demo") {//Mortgage router
            return <MortgageRouting user={props.user} account={props.account} handleLogout={props.handleLogout} />
        }
        else if (props.account.account_type === "Grand Welcome") {//G welcome router
            return <GrandWelcomeRouting user={props.user} account={props.account} handleLogout={props.handleLogout} />
        }
        else if ((props.account.account_type === "Jewelry") || (props.account.account_type === "MVI")) {//Jewelry router
            return <JewelryRouting user={props.user} account={props.account} handleLogout={props.handleLogout} />
        }
        else if (props.account.account_type === "DDK") {//DDK router
            return <DDKRouting user={props.user} account={props.account} handleLogout={props.handleLogout} />
        }
        else//Two men router
            return <TwoMenRouting user={props.user} account={props.account} handleLogout={props.handleLogout} />
    }

    return (<>
        {(props.user.is_staff)
            /* Staff */
            ? <>
                <StaffRouting user={props.user} account={props.account} handleLogout={props.handleLogout} />
            </>
            /* Client */
            : <>
                {pickClientRouter()}
            </>
        }
    </>
    )
}
export default Routing
